import React from 'react';

import { pricing } from './constants';
import verifLogo from '../assets/verifier.png';
function ListTarif({handleSelectTarif}) {
    return (
        <div className=' flex gap-[1rem] max-lg:flex-wrap'>
            {pricing.map((p)=> 
            (
                <div id={p.id}
                className={`w-[19rem] max-lg:w-full h-full px-6 border
                border-cyan-400 rounded-[2rem] lg:w-auto even:py-8 odd:py-8 odd:my-8
              `}>
               
             
                <h4 className={`font-sans text-3xl mb-4 text-left
                      ${p.id === '0' ? 'text-orange-500' : p.id === '1' ? 'text-purple-400' : p.id === '2' ? 'text-sky-500' : ''} `}>
                    {p.title}
                </h4>
                <p className='text-left font-code text-teal-200'> 1 mois d'essai gratuit.</p>

                <p className='text-left font-code text-n-2'> {p.description}</p>

                <div className='text-left h-[5.5rem]'>
                {p.price && (
                    <div className='flex flex-row'>
                        <div className=' h3'>€</div>
                        <div className='text-[5.5rem] font-bold leading-none'>{p.price}</div>
                    </div>
                )}

                </div>

                <button onClick={() => handleSelectTarif(p.title)}
                className={` button w-full mt-5 p-3 rounded-2xl font-semibold border-2 
                hover:text-sky-200
  ${p.price ? 'bg-teal-500 text-black border-sky-900' : 'bg-transparent text-white border-teal-300'}`}>
  {p.price ? "Commencer" : "Nous contacter"}
</button>

<ul className='mt-2'>
{p.features.map((feature,index) => (
    <li key={index} className=' text-left'>
        <div className='flex flex-row items-center gap-4 border-t border-n-5 py-2'>
    
        <img src={verifLogo} width={24} height={24} alt='verif' />
        <p> {feature} </p>

        </div>
    </li>
))}

</ul>
                </div> 
            )
            )}
        </div>
    );
}

export default ListTarif;