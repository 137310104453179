// Dans Header.js
import React from "react";
import Projet from "../Projet";
import { product1 } from "../constants";
function EvalPlus({handleAccueilClick, handleProductsClick, handleWhyClick, handleProjetClick}) {


 
    return (
   <div >
   <Projet  roadmap={product1} recentproject={"Exploitation d'évaluations."}/>
    </div>
    );
  }
  
  export default EvalPlus;