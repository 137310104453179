import React, {} from 'react';
import { idea } from './constants';
import { Button } from './design/MovinBorder';
function Idea() {
  

    return (
  
  <div className=' w-full mt-12 grid lg:grid-cols-4 grid-cols-1 gap-10' >
  
    {idea.map((item,index) => (
     <Button key={item.id}
     borderRadius='1.75rem'
     duration={Math.floor(Math.random() * 10000 + 5000)}
     > 
  <div className=' p-2 flex flex-col md:flex-row items-center gap-5 '> 
  <img src={item.image} alt="idea4logo" className='   w-32 h-32'  />  
   <div className='flex flex-col gap-5'>
   <h1 className='  font-sans 4xl:text-2xl 3xl:text-xl 2xl:text-base lg:text-sm font-bold'> {item.title} </h1>
   <p className=' font-code text-n-2 4xl:text-lg 3xl:text-md xl:text-sm lg:text-xs'>{item.description}</p>
    </div>
    
    </div> </Button>)
   ) }

</div>
    );
  }
  
  export default Idea;


