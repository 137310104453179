import React ,{useState} from 'react';

import tanoEdLogo from '../assets/logoTanoEd.png'
import { useMediaQuery } from 'react-responsive';
function Footer({handleProductsClick, handleContactFormClick}) {
  const [mail, setMail] = useState("")
  const handleMailChange = (e) => {
    setMail(e.target.value);
  }

  const isMd = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  const isextraSm = useMediaQuery({ minWidth: 0, maxWidth: 563 });
    return (
  
  <div className=' flex flex-row tracking-wide h-fit p-2 bg-[#001a33] border-b-2  border-slate-300 ' >

<div className=' 2xl:w-1/4 xl:w-1/6'></div>
   <div className=' 2xl:w-2/4 xl:w-4/6  p-2'> 
   <div className=' flex md:flex-row flex-col gap-5 items-center '>

      <div className=' flex flex-col w-1/2 items-start gap-2 text-left '>
    <img src={tanoEdLogo} alt="tano" className=' mt-2  w-20' /> 
          <p className=' font-sans text-base'> Restez informé </p>
      <p className=' text-sm'> Inscrivez-vous à notre newsletter pour des{isMd && <br/>} mises à jour régulières.
      {!isMd && <br/>} Aucun spam garanti.
      </p>
      <p className=' font-sans text-sm'>
      Votre email
      </p> 
      <div className={`flex ${isextraSm ? 'flex-col' : 'flex-row'} gap-2`}>
        <input type="text" onChange={handleMailChange} value={mail} placeholder='exemple@mail.com' className=' p-2 rounded bg-slate-200 border-slate-300 border' /> 
        <button className=' p-2 rounded bg-slate-200 border-sky-300 border
        text-cyan-700 shadow-inner  shadow-sky-100 hover:bg-sky-400
        '> S'abonner </button>
      </div>


    </div>
    <div className=' flex md:flex-row flex-col w-1/2  gap-2 h-full'>
    <div onClick={handleProductsClick} className=' flex md:flex-col flex-row text-left mt-5 gap-5 
    md:w-1/3 w-full   hover:border-sky-500  border-2 border-transparent p-2 hover:cursor-pointer 
    hover:bg-sky-600 rounded-2xl'>
      <h1 className=' font-sans 2xl:text-base xl:text-sm text-xs'> Fonctionnalités </h1>
     {!isextraSm &&  <p className=' text-xs'>Evaluations, groupe de besoins, progression et dashboard de savoir-faires. <br/> TanoEd réponds à vos besoins.</p>
}
    </div>

    <div className=' flex md:flex-col flex-row text-left mt-5 gap-5 md:w-1/3 w-full hover:border-sky-500  border-2 border-transparent p-2 hover:cursor-pointer 
    hover:bg-sky-600 rounded-2xl'>
      <h1 className=' font-sans 2xl:text-base xl:text-sm text-xs' >  A propos de TanoEd </h1>
    {!isextraSm &&   <p className=' text-xs'> Qu'il y a t il dérrière l'application ? </p>
}
    </div>

    <div  onClick= {handleContactFormClick} className=' flex md:flex-col flex-row text-left mt-5 gap-5 md:w-1/3 w-full hover:border-sky-500  border-2 border-transparent p-2 hover:cursor-pointer
    
    hover:bg-sky-600 rounded-2xl'>
      <h1 className=' font-sans 2xl:text-base xl:text-sm text-xs'>  Nous contacter </h1>
    {!isextraSm &&  <p className=' text-xs'> Besoin de quoi que ce soit ? <br/> Contactez-nous. Nous pouvons vous aider. </p>
}
    </div>
   </div>
   
    </div>

    </div> 

<div className=' 2xl:w-1/4 xl:w-1/6'> </div>


</div>
    );
  }
  
  export default Footer;