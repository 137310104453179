import React from 'react';

import { solutions } from './constants';
import bgimg from '../assets/bg.png';
import  {PinContainer}  from './design/PinContainer';
function Products({sectionRef,handleSelectProduct}) {
 

  
    return (
      <div  ref={sectionRef} className='flex flex-col h-full items-center text-center align-middle'
      style={{
          background: `
     
           radial-gradient(circle at 5% 8%, #068aff, transparent 20%),
           radial-gradient(circle at 95% 125%, #068aff, transparent 20%),
          #001a33
      `
      }}>

<div className='container  mt-28 mb-28 '>
  <h2 className='  font-sans text-4xl font-semibold p-2 '> Nos solutions</h2>
  <p className=' font-code text-n-2 tracking-wide  text-xl   '>  Propulsez chaque élève vers l'excellence en mathématiques grâce à une
  <span className=' text-orange-300'>  pédagogie data-driven</span>.
</p>


<div className=' flex flex-wrap items-center justify-center p-4 gap-x-24 gap-y-8 mt-10'>

  {solutions.map((solution) => (
    <div key={solution.id} onClick={()=>handleSelectProduct(`product${solution.id}`)}className=' sm:h-[41rem] h-[32rem]
     lg:min-h-[32.5rem] flex items-center justify-center
     sm:w-[570px] w-[80vw]'>
<PinContainer title={solution.title} href="#" >
<div className='relative flex items-center justify-center 
sm:w-[570px] w-[70vw] overflow-hidden
sm:h-[25vh] h-[20vh]
 mb-10'>

    <div className='relative 
    w-full h-full overflow-hidden lg:rounded-3xl bg-[#13162d]'>
      <img src={bgimg} alt='bg-img' /> 
    </div>
    <img src={solution.image} alt="imagesoluce"
    className='z-10 absolute bottom-0 w-full ' />
  
</div>
<h1 className=' text-left font-sans font-semibold lg:text-2xl
 md:text-xl text-base line-clamp-1
 mb-5'>
  {solution.title}
</h1>

<p className=' text-left  font-code lg:text-base lg:font-normal font-light text-sm line-clamp-3 text-n-2'>
  {solution.description}
</p>

<div className=' flex items-center justify-between mt-7 mb-3'>

  <div key={solution.icon} className=' border border-white/[0.2] rounded-full bg-orange-200
  flex justify-center items-center'>
  <img src={solution.icon} alt={solution.icon}
  className='p-2 w-10  h-10' /> 
  </div>

  <div>
    <p className='font-sans text-orange-200'> En savoir plus ⮕</p>

  </div>
</div>
  </PinContainer>
      </div> 
  ))}
</div>
  </div>

</div>

    );
  }
  
  export default Products;