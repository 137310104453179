// Dans Header.js
import React from "react";
import Projet from "../Projet";
import { product3 } from "../constants";
function GroupePlus({handleAccueilClick, handleProductsClick, handleWhyClick, handleProjetClick}) {


 
    return (
   <div >
   <Projet  roadmap={product3} recentproject={"Outil de différenciation pédagogique."}/>

    </div>
    );
  }
  
  export default GroupePlus;