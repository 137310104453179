import React from 'react';
import logo from '../assets/dodecaedre.png';
import starsimg from '../assets/stars.svg';
import ListTarif from './ListTarif';
import { LeftLine, RightLine } from './design/TarifDesign';

function Tarif({sectionRef, handleSelectTarif}) {
    return (
        <div ref={sectionRef} className='flex flex-col h-full items-center text-center align-middle'
            style={{
                background: `
                radial-gradient(circle at 95% 10%, #068aff, transparent 20%),
                #001a33
            `
            }}>
            <div className='container relative z-2 mb-10 '>
                {/* Ici, on cache en dessous de 'md' et on affiche à partir de 'md' */}
                <div className='hidden md:flex transition-opacity duration-500 opacity-0 md:opacity-100 justify-center relative mb-[6.5rem]'>
                <img 
                    src={logo} 
                    className='relative z-1'
                    width={350} 
                    height={350} 
                    alt="MathObject"
                />
                <div className='absolute top-1/2 left-1/2 w-[60rem] -translate-x-1/2 -translate-y-1/2 pointer-events-none'>
                    <img src={starsimg} className=' w-full '
                    width={950} height={400} alt="Stars"/>
                </div>

</div>
<h2 className='  font-sans text-4xl font-semibold p-2 '> Plans Tarifaires</h2>
<p className=' font-code text-xl text-n-2 '>Une licence annuelle renouvelable. </p>
<div className='relative mt-10'>

    <ListTarif handleSelectTarif={handleSelectTarif} />
    <LeftLine />
    <RightLine />
</div>
            </div>
        </div>
    );
}

export default Tarif;