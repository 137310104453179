import Header from './components/Header';
import Banner from './components/Banner';
import Products from './components/Products';
import Projet from './components/Projet';
import Why from './components/Why';

import GetIt from './components/GetIt';
import Footer from './components/Footer';
import Coordonnées from './components/Coordonnées';
import { useState, useRef, useEffect } from 'react';
import MentionLegales from './components/MentionLegales';
import Politique from './components/Politique';
import ContactForm from './components/ContactForm';
import Tarif from './components/Tarif';
import EvalPlus from './components/KnowMore/EvalPlus';

import { roadmap } from './components/constants';
import AcquisitionPlus from './components/KnowMore/AcquisitionPlus';
import GroupePlus from './components/KnowMore/GroupePlus';
import ProgressionPlus from './components/KnowMore/ProgressionPlus';

function App() {

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);

  const [scrollTarget, setScrollTarget] = useState(null);
  const [messageTarif, setMessageTarif] = useState('');
  const scrollToSection = (ref, offset = 50) => {
    if (ref.current) {
      const elementPosition = ref.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  
  const [menu, setMenu] = useState("accueil")
  useEffect(() => {
    if (scrollTarget) {
      scrollToSection(scrollTarget.ref, scrollTarget.offset);
      setScrollTarget(null); // Reset the scroll target after scrolling
    }
  }, [menu, scrollTarget]);


  const handleMentionClick = () => {
    setMenu("mentions");
    window.scrollTo({ top: 0, behavior: 'smooth' });

  }

  const handleAccueilClick = () => {
    console.log("CLIQUER" );
    setMenu("accueil");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handlePolitiqueClick = () => {
    setMenu("politique");
    window.scrollTo({top : 0 , behavior:'smooth'})
  }

  const handleProductsClick = () => {
    setMenu('accueil')
    setScrollTarget({ ref: section1Ref, offset: 100 });
    
  }

  const handleWhyClick= () => {
    setMenu('accueil')
    setScrollTarget({ ref: section2Ref, offset: 100 });
  }

  const handleProjetClick = () => {
    setMenu('accueil')
    setScrollTarget({ ref: section3Ref, offset: 100 });
  }

  const handleContactFormClick = () => {
    console.log("contact click !")
    setMenu("contact")
    setMessageTarif('')
    window.scrollTo({ top: 0, behavior: 'smooth' });

  }
  const handleTarifClick = () => {
    console.log("tarif click")
    setMenu('accueil')
   setScrollTarget({ref: section4Ref, offset:100})
  }
  const handleSelectProduct = (item) => {
    console.log("wheshh !!" , item )
    setMenu(item);

  }
  const handleSelectTarif = (item ) => {
    setMenu("contact")
    console.log("item",item)
    if(item === "Basic") {
      setMessageTarif('Bonjour, je souhaite souscrire à la licence annuelle pour le plan "Basic". Merci de me communiquer les détails et la procédure à suivre.')
    }
    else if(item ==="Premium"){
      setMessageTarif("Bonjour, je souhaite souscrire à la licence annuelle pour le plan 'Premium'. Pouvez-vous me fournir les informations nécessaires pour procéder ?")
    }
    else{
      setMessageTarif("Bonjour, je suis intéressé par une licence annuelle pour un plan 'Personnalisé'. Merci de me communiquer les détails et la procédure à suivre.")
    }

  }
  return (
    <div className="App  overflow-x-hidden">

    
      <Header handleAccueilClick={handleAccueilClick} handleProductsClick={handleProductsClick} handleWhyClick={handleWhyClick} 
      handleProjetClick={handleProjetClick} handleContactFormClick={handleContactFormClick} handleTarifClick={handleTarifClick}  /> 
      {menu === "accueil" && 
      <>
      <Banner handleProductsClick={handleProductsClick} /> 
      
      <Products sectionRef={section1Ref}  handleSelectProduct={handleSelectProduct}  /> 
      <Why sectionRef={section2Ref} />
       <Tarif sectionRef={section4Ref} handleSelectTarif={handleSelectTarif}/>  
      <Projet sectionRef={section3Ref} roadmap={roadmap} /> 
   

     <GetIt handleContactFormClick={handleContactFormClick} /> 

   
     
     </> }

     {menu === "mentions" && 
     <MentionLegales /> 
     }

     {menu ==="politique" && 
     <Politique /> }

     {menu ==="contact" && 
     <ContactForm handleAccueilClick={handleAccueilClick} messageTarif={messageTarif}/> }

     {menu ==="product0" &&
     <EvalPlus /> }
     {menu ==="product1" &&
     <AcquisitionPlus /> }

{menu ==="product2" &&
     <GroupePlus /> }
     {menu ==="product3" &&
     <ProgressionPlus /> }
<Footer  handleProductsClick={handleProductsClick} handleContactFormClick={handleContactFormClick}  /> 

<Coordonnées handleMentionClick={handleMentionClick} handlePolitiqueClick={handlePolitiqueClick} /> 
    </div>
  );
}

export default App;
