import { useEffect, useState } from "react";
import { MouseParallax } from "react-just-parallax";

import PlusSvg from "../../svg/PlusSvg";
export const Gradient = () => {
  return (
    <>
      <div className="relative z-1 h-6 mx-2.5 bg-n-11 shadow-xl rounded-b-[1.25rem] lg:h-6 lg:mx-8" />
      <div className="relative z-1 h-6 mx-6 bg-n-11/70 shadow-xl rounded-b-[1.25rem] lg:h-6 lg:mx-20" />
    </>
  );
};

export const BottomLine = () => {
  return (
    <>
      <div className="hidden absolute top-[55.25rem] left-10 right-10 h-0.25 bg-n-6 pointer-events-none xl:block" />

      <PlusSvg className="hidden absolute top-[54.9375rem] left-[2.1875rem] z-2 pointer-events-none xl:block" />

      <PlusSvg className="hidden absolute top-[54.9375rem] right-[2.1875rem] z-2 pointer-events-none xl:block" />
    </>
  );
};

const Rings = () => {
  return (
    <>
      <div className="absolute top-1/2 left-1/2 w-[65.875rem] aspect-square border border-n-2/10 rounded-full -translate-x-1/2 -translate-y-1/2" />
      <div className="absolute top-1/2 left-1/2 w-[51.375rem] aspect-square border border-n-2/10 rounded-full -translate-x-1/2 -translate-y-1/2" />
      <div className="absolute top-1/2 left-1/2 w-[36.125rem] aspect-square border border-n-2/10 rounded-full -translate-x-1/2 -translate-y-1/2" />
      <div className="absolute top-1/2 left-1/2 w-[23.125rem] aspect-square border border-n-2/10 rounded-full -translate-x-1/2 -translate-y-1/2" />
     
    </>
  );
};

export const BackgroundCircles = ({ parallaxRef }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div className="absolute 
    -top-[42.375rem] left-1/2 w-[78rem] aspect-square border border-n-2/5 rounded-full 
    -translate-x-1/2 md:-top-[38.5rem] xl:-top-[32rem]">
      <Rings />

      {/* Moving background colored circle balls */}
      <MouseParallax strength={0.07} parallaxContainerRef={parallaxRef}>
        <div className="absolute bottom-1/2 left-1/2 w-0.25 h-1/2 origin-bottom rotate-[46deg]">
          <div
            className={`w-2 h-2 -ml-1 -mt-36 bg-gradient-to-b from-[#DD734F] to-[#1A1A32] rounded-full transition-transform duration-500 ease-out ${
              mounted ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"
            }`}
            style={{
              background: "linear-gradient(to bottom, #DD734F, #1A1A32)",
            }} />
        </div>

      
        <div className="absolute bottom-1/2 left-1/2 w-0.25 h-1/2 origin-bottom rotate-[54deg]">
          <div
            className={`hidden w-4 h-4 -ml-1 mt-[12.9rem] bg-gradient-to-b from-[#B9AEDF] to-[#1A1A32] rounded-full xl:block transit transition-transform duration-500 ease-out ${
              mounted ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"
            }`}
          />
        </div>
        

        <div className="absolute bottom-1/2 left-1/2 w-0.25 h-1/2 origin-bottom -rotate-[65deg]">
          <div
            className={`w-3 h-3 -ml-1.5 mt-52 bg-gradient-to-b from-[#B9AEDF] to-[#1A1A32] rounded-full transition-transform duration-500 ease-out ${
              mounted ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"
            }`}
            style={{
              background: "linear-gradient(to bottom, #b303ff, #1A1A32)",
            }} />
        </div>
       
        <div className="absolute bottom-1/2 left-1/2 w-0.25 h-1/2 origin-bottom -rotate-[85deg]">
          <div
            className={`w-6 h-6 -ml-3 -mt-3 bg-gradient-to-b from-[#88E5BE] to-[#1A1A32] rounded-full transition-transform duration-500 ease-out ${
              mounted ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"
            }`}
            style={{
              background: "linear-gradient(to bottom, #03ffe8, #1A1A32)",
            }}  />
        </div>
        
        <div className="absolute bottom-1/2 left-1/2 w-0.25 h-1/2 origin-bottom rotate-[70deg]">
          <div
            className={`w-6 h-6 -ml-3 -mt-3  rounded-full transition-transform duration-500 ease-out ${
              mounted ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"
            }`}
            style={{
              background: "linear-gradient(to bottom, #88E5BE, #1A1A32)",
            }} />
        </div>

          {/* Pyramide (2D) */}
  <div className="absolute bottom-1/3 left-1/2 w-0.25 h-1/2 origin-bottom rotate-[42deg]">
  <svg
    className={`w-10 h-10 -ml-6 -mt-36 transition-transform duration-500 ease-out ${
      mounted ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"
    }`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
  >
    {/* Définition du gradient */}
    <defs>
      <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style={{ stopColor: "#7bff06", stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: "#1A1A32", stopOpacity: 1 }} />
      </linearGradient>
    </defs>

    {/* Pyramide avec dégradé */}
    <polygon points="50,10 90,90 10,90" stroke="#DD734F" fill="url(#grad1)" />
  </svg>
  </div>
  {/* Cube (2D) */}
  <div className="absolute bottom-1/2 left-1/2 w-0.25 h-1/2 origin-bottom -rotate-[56deg]">
    <svg
      className={`w-16 h-16 -ml-8 -mt-32 transition-transform duration-500 ease-out ${
        mounted ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"
      }`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
       <defs>
      <linearGradient id="grad2" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style={{ stopColor: "#44ff03", stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: "#1A1A32", stopOpacity: 1 }} />
      </linearGradient>
    </defs>
      <rect x="25" y="25" width="50" height="50" stroke="#88E5BE" fill="url(#grad2)" />
    </svg>
  </div>
      </MouseParallax>
    </div>
  );
};