import React from 'react';
import Idea from './Idea';
function Why({sectionRef}) {
  

    return (
  
   <div ref={sectionRef} className='flex flex-col h-full items-center text-center align-middle'
            style={{
                background: `
           
                 radial-gradient(circle at -5% 18%, #068aff, transparent 20%),
                 radial-gradient(circle at 95% 125%, #068aff, transparent 20%),
                #001a33
            `
            }}>

               
<div className='container  mt-28 mb-28 '>
  <h2 className='  font-sans text-4xl font-semibold p-2 '> Pourquoi utiliser TanoEd ?</h2>
  <p className=' font-code text-xl text-n-2 mb-10'> Maximisez l'efficacité pédagogique : 
    <span className=' text-orange-200'> 
  gain de temps </span> et <span className=' text-orange-200'>amélioration des résultats </span> éducatifs.  </p>

  <Idea /> 
   </div> 
 
 

</div>
    );
  }
  
  export default Why;