import React from 'react';


function GetIt({handleContactFormClick}) {


    return (
      <div className='flex flex-col h-full items-center text-center align-middle'
      style={{
          background: `
     
           radial-gradient(circle at -5% 18%, #068aff, transparent 20%),
           radial-gradient(circle at 95% 125%, #068aff, transparent 20%),
          #068aff
      `
      }}>


<div  className=' flex p-10  flex-col gap-5 2xl:w-1/4 xl:w-1/3 lg:w-1/3  
w-full h-fit items-center'>

  <h1 className='p-2  font-sans text-sm text-white'> 
    Commencer maintenant !</h1>
   
   <p className='text-slate-200 text-4xl xl:text-3xl lg:text-xl'>
     <span className=' text-white  font-sans font-bold text-3xl
     '>Accélérez </span>
      votre gestion éducative</p>
    <p className=' lg:text-slate-300 p-2 text-base xl:text-base lg:text-sm
    
    font-code text-n-2'> Découvrez comment TANOED peut transformer votre façon de travailler et simplifier l'évaluation et la progression des élèves.</p>
<button onClick={handleContactFormClick} className=' p-5 rounded-3xl
 bg-[#001a33] w-fit font-bold items-center
border-2 border-sky-700 shadow-sm shadow-zinc-600
hover:shadow hover:border-transparent button hover:text-sky-200
'> Obtenir TanoEd ! </button>
</div>
   
</div>
    );
  }
  
  export default GetIt;