// Dans Header.js
import React, {useState} from "react";
import logo from '../assets/logoTanoEdnoText.png';
import { useMediaQuery } from 'react-responsive';
function Header({handleAccueilClick, handleProductsClick, handleWhyClick, handleTarifClick, handleProjetClick, handleContactFormClick}) {
  const isMd = useMediaQuery({ minWidth: 100, maxWidth: 810 });
  const [isMenuOpen, setIsMenuOpen] = useState(false);


  const handleContactClick = () => {
    handleContactFormClick()
    setIsMenuOpen(!isMenuOpen)
  }
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleMenuAccueilClick = () => {
    handleAccueilClick()
    setIsMenuOpen(!isMenuOpen);
  }
  const handleMenuProductsClick = () => {
    handleProductsClick();
    setIsMenuOpen(!isMenuOpen)
  }

  const handleMenuWhyClick = () => {
    handleWhyClick();
    setIsMenuOpen(!isMenuOpen);
  }

  const handleMenuProjetClick = () => {
    handleProjetClick();
    setIsMenuOpen(!isMenuOpen);
  }
  const handleMenuTarifClick = () => {
    handleTarifClick();
    setIsMenuOpen(!isMenuOpen);
  }
    return (
   <div className="" >
   {!isMd &&  <div className="   fixed top-0 bg-opacity-80
    bg-black w-full flex flex-row gap-5  justify-between  tracking-wide
     items-center  p-2  z-[100]
    ">

  <div className="flex flex-row items-center">
       <img src={logo} className='  2xl:w-16 xl:w-16 lg:w-14 w-10 ml-5'  alt="logo " />
<p className="font-sans text-n-1 text-xl"> TanoEd</p>
       </div>
        <ul className=' flex flex-row 2xl:text-base  xl:text-base lg:text-sm text-xs 
        font-code uppercase  text-zinc-300
         gap-5   '>
            <li  onClick={handleAccueilClick} className=' 2xl:p-3 p-2 hover:text-white hover:font-bold  hover:border-b-cyan-300 border-2 border-transparent
            hover:cursor-pointer '> Accueil</li>
              <li onClick ={handleProductsClick}className=' 2xl:p-3 p-2 hover:text-white hover:font-bold  hover:border-b-cyan-300 border-2 border-transparent
            hover:cursor-pointer '> Fonctionnalités</li>
                <li onClick={handleWhyClick} className='  2xl:p-3 p-2 hover:text-white hover:font-bold  hover:border-b-cyan-300 border-2 border-transparent
            hover:cursor-pointer '> Pourquoi ? </li>
                  <li onClick={handleTarifClick} className='2xl:p-3 p-2 hover:text-white hover:font-bold  hover:border-b-cyan-300 border-2 border-transparent
            hover:cursor-pointer '> Tarifs</li>
          
                 <li onClick={handleProjetClick} className='2xl:p-3 p-2 hover:text-white hover:font-bold  hover:border-b-cyan-300 border-2 border-transparent
            hover:cursor-pointer '> A Venir</li>
           
         
            </ul>

       
<button onClick={handleContactFormClick} className=' button xl:p-5 lg:text-sm text-xs lg:p-3
 bg-sky-300 rounded-2xl text-black  font-bold  hover:cursor-pointer 
hover:bg-sky-100 hover:text-black hover:transition hover:duration-1000 hover:scale-150
hover:shadow-sm hover:border-4 hover:border-sky-700 2xl:w-fit mr-5 '> Obtenir TANOED </button>

  </div>}

  {isMd && 
    <div className={`flex items-center justify-between p-5 bg-black 
     ${!isMenuOpen &&  'opacity-80' } shadow-md w-full  
    fixed top-0 bg-opacity-80 z-[100]`}>
      <img src={logo} className="xl:w-16 lg:w-14 w-10" alt="logo" />
      <div className="flex items-center space-x-5">
    
        <button
          className="text-2xl lg:hidden"
          onClick={handleMenuToggle}
        >
          ☰
        </button>
      </div>
      <ul className={`flex-col 
      
       items-center   ${isMenuOpen ? 'flex h-[50vh]' : 'hidden'} 
      absolute  top-full left-0 w-full  bg-[#001a33] p-5 
       font-sans text-left text-xl`}>
        <li onClick={handleMenuAccueilClick} className=" p-2  w-full border-b-2 border-slate-400 
        hover:cursor-pointer hover:bg-sky-900">
          Accueil
        </li>
        <li onClick={handleMenuProductsClick} className="p-2  w-full border-b-2 border-slate-400 
        hover:cursor-pointer hover:bg-sky-900">
        Fonctionnalités
        </li>
        <li onClick={handleMenuWhyClick} className="p-2  w-full border-b-2 border-slate-400 
        hover:cursor-pointer hover:bg-sky-900">
          Pourquoi ?
        </li>
        <li onClick={handleMenuTarifClick} className="p-2  w-full border-b-2 border-slate-400
         hover:cursor-pointer hover:bg-sky-900">
        Tarifs
        </li>
        <li onClick={handleMenuProjetClick} className="p-2  w-full border-b-2 border-slate-400 
        hover:cursor-pointer hover:bg-sky-900">
          A Venir
        </li>
       
        <button onClick={handleContactClick} className=" button 
        p-5 m-10 bg-sky-700 rounded-2xl text-white font-normal 
         hover:cursor-pointer hover:bg-sky-100 hover:text-black
          hover:transition hover:duration-1000 hover:scale-150
         hover:shadow-sm hover:border-4 hover:border-sky-700">
          Obtenir TANOED
        </button>
      </ul>
    </div>
    
    }
  
    </div>
    );
  }
  
  export default Header;