import React from 'react';
import grid from '../assets/grid.png';
import loading from '../assets/loading.png';
import verif from '../assets/verifier.png';
function Projet({sectionRef,roadmap,recentproject}) {

    return (
  
<div ref={sectionRef}  className={`flex flex-col  h-full items-center text-center align-middle  `}
style={{
  background: `
    radial-gradient(circle at 5% 10%, #068aff, transparent 20%),
    #001a33
  `
}}>
  
<div className='container  mt-28 mb-28 '>
{!recentproject ?

<div><h2 className='  font-sans text-4xl font-semibold p-2 '> Ce sur quoi nous travaillons</h2> 
  <p className=' font-code text-xl text-n-2 mb-10'> Soyez prêt ! </p>
  </div> :

  
<div><h2 className='  font-sans text-4xl font-semibold p-2 '> {recentproject}</h2> 
  <p className=' font-code text-xl text-n-2 mb-10'> Tester maintenant !  </p>
  </div>
}

<div className=' relative grid gap-6 md:grid-cols-2 md:gap-4 md:pb-[7rem]'>
  {roadmap.map((item) => {
    return (
    <div key={item.id} className=' md:flex even:md:translate-y-[7rem] p-0.25 rounded-[2.5rem]
   bg-conic-gradient mt-5 '>
        <div className='relative p-8 rounded-[2.5rem]  overflow-hidden xl:p-15  '
        style={{
          background: `
    #001a33
  `
        }}>
        
   
              <div className=' absolute top-0 left-0 max-w-full  '>
                <img src={grid}
                className='w-full'
                alt="grid" 
                width={550}
                height={550}/>
                </div>

    <div className=' relative z-1  '>
        <div className='flex flex-col   justify-between'>
          <div className='flex flex-row items-center w-full   justify-between'>
          <p className='font-code text-n-2'> [ {item.date}  ] </p>

          <div className='flex flex-row bg-sky-200 text-black rounded p-1'>
           {item.status==="En cours" ?  <img src={loading} className='mr-2.5' 
            alt="loading"
            width={20}
            height={20}/>

            :
            <img src={verif} className='mr-2.5' 
            alt="loading"
            width={20}
            height={20}/>

      }
            <p className=' font-code'> {item.status} </p>

            </div>
           </div> 

            <div className='mb-10'>
              <img src={item.imageUrl} 
              className='w-full' 
              width={630}
              height={420}
              alt="imageroadmap" />

              </div>

              <h4 className=' h4 mb-5 font-semibold text-left '> {item.title} </h4>

              <p className=' body-2 text-n-2 text-justify'> {item.text} </p>
          </div> 
      </div>
          </div>
      </div>
    )
  })}
</div>

</div> 
</div>
    );
  }
  
  export default Projet;