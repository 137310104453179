import React, {} from 'react';

function Politique({handleMentionClick}) {
  

    return (
  
  <div className=' flex flex-row text-left p-5   text-base   gap-5 justify-between tracking-wide bg-[#001a33] ' >

    <div className=' w-1/4'> </div>

    <div className='  flex flex-col  gap-2 mt-20 p-5' >
 <h1 className=' font-sans text-xl'>Politique de Confidentialité de TanoEd</h1>
    <p>La confidentialité et la sécurité des données personnelles de nos utilisateurs sont très importantes pour TanoEd. Nous respectons la confidentialité de vos données personnelles et veillons à ce qu’elles soient traitées conformément à la législation en vigueur sur la protection des données, en particulier le Règlement général sur la protection des données (RGPD).</p>
    <p>Cette politique de confidentialité explique comment nous collectons, utilisons et protégeons vos données personnelles lorsque vous utilisez notre site web <a href="https://www.tanoed.com" >www.tanoed.com</a>. En utilisant notre site web, vous acceptez la collecte, l’utilisation et la divulgation de vos données personnelles conformément à cette politique de confidentialité.</p>

    <h2 className=' font-sans text-xl'>Collecte de données personnelles</h2>
    <p>Nous pouvons collecter différentes catégories de données personnelles vous concernant lorsque vous utilisez notre site web, notamment :</p>
    <ul>
        <li>Les informations que vous nous fournissez directement, par exemple lorsque vous remplissez un formulaire de contact ou d’inscription ;</li>
        <li>Les informations que nous collectons automatiquement lorsque vous utilisez notre site web, telles que votre adresse IP, les pages que vous consultez et les liens sur lesquels vous cliquez ;</li>
        <li>Les informations que nous recevons de tiers, par exemple si vous vous connectez à notre site web via un réseau social ou si vous nous fournissez des informations de paiement via un prestataire de paiement tiers.</li>
    </ul>

    <h2 className=' font-sans text-xl'>Utilisation des données personnelles</h2>
    <p>Nous utilisons les données personnelles que nous collectons pour différentes finalités, notamment :</p>
    <ul>
        <li>Pour vous fournir les produits, services et informations que vous avez demandés ;</li>
        <li>Pour personnaliser et améliorer votre expérience utilisateur sur notre site web ;</li>
        <li>Pour vous contacter concernant les produits, services et informations que nous pensons susceptibles de vous intéresser ;</li>
        <li>Pour respecter les obligations légales auxquelles nous sommes soumis.</li>
    </ul>

    <h2 className=' font-sans text-xl'>Partage des données personnelles</h2>
    <p>Nous ne partageons vos données personnelles avec des tiers que dans les cas suivants :</p>
    <ul>
        <li>Lorsque cela est nécessaire pour fournir les produits, services et informations que vous avez demandés, par exemple si nous utilisons un prestataire de paiement tiers pour traiter vos paiements ;</li>
        <li>Lorsque cela est nécessaire pour respecter les obligations légales auxquelles nous sommes soumis ;</li>
        <li>Lorsque vous avez donné votre consentement à ce partage.</li>
    </ul>

    <h2 className=' font-sans text-xl'>Protection des données personnelles</h2>
    <p>Nous prenons des mesures de sécurité appropriées pour protéger vos données personnelles contre tout accès non autorisé, toute modification, toute divulgation ou toute destruction non autorisée. Nous limitons l’accès à vos données personnelles aux personnes qui ont besoin d’y accéder pour remplir leur mission.</p>

    <h2 className=' font-sans text-xl'>Vos droits</h2>
    <p>Vous disposez de certains droits en ce qui concerne vos données personnelles, notamment le droit d’accéder à vos données personnelles, le droit de les rectifier, le droit de les effacer et le droit de vous opposer à leur traitement. Si vous souhaitez exercer ces droits, veuillez nous contacter à l’adresse e-mail suivante : <a href="mailto:mangata@tanoed.com">mangata@tanoed.com</a>.</p>

    <h2>Modification de la politique de confidentialité</h2>
    <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment en publiant une version mise à jour sur notre site web.</p>

    </div>

    <div className=' w-1/4'> </div>
</div>

    );
  }
  
  export default Politique;



