// Dans Banner.js
import landingImage from '../assets/bannerTest.webp';
import { BackgroundCircles, Gradient } from './design/HeroDesign';
import { ScrollParallax } from 'react-just-parallax';
import { heroIcons } from './constants';
import Chart from '../assets/Chart.png';
import tanoEdImage from '../assets/TanoEdRobot.png';
import { useMediaQuery } from 'react-responsive';
function Banner({handleProductsClick , sectionRef}) {
  const isMd = useMediaQuery({ minWidth: 0, maxWidth: 1023 });

    return (

        
      <div ref={sectionRef} className="  p-1  " style={{
        background: `

         radial-gradient(circle at -50% 28%, #068aff, transparent 80%),
        #001a33
      `
  }}>

      
      <div className='   lg:h-20    h-24'>  </div>
 
      <div className="container relative  ">

        <div className="relative z-1 max-w-[62rem]
         mx-auto text-center mb-[3.875rem] md:mb-20 lg:mb-[6.25rem] p-10">
          <h1 className="  font-sans lg:text-5xl text-xl mb-6">
          Dynamisez votre enseignement des mathématiques avec {` `}
            <span className="inline-block relative">
              TanoEd{" "}
             <CurveComponent />
            </span>
          </h1>
          <p className="body-1 font-sans text-xl max-w-3xl mx-auto mb-6 text-n-2 lg:mb-8">
          Suivez les résultats de vos élèves et adaptez vos pratiques pédagogiques grâce à nos outils avancés.
          </p>
          <button onClick= {handleProductsClick} className=" button p-3 4xl:text-base 3xl:text-lg 2xl:text-base xl:text-sm lg:text-xs font-bold tracking-wide bg-sky-800  shadow-sm shadow-black rounded-3xl text-white
            hover:scale-110 hover:transition-all hover:duration-300 hover:shadow-none hover:bg-sky-200 hover:text-black"> Découvrir  ⮕ </button>
        </div>

 <div className="relative max-w-[23rem] mx-auto md:max-w-5xl xl:mb-24">
          <div className="relative z-1 p-0.5 rounded-2xl bg-conic-gradient shadow-2xl shadow-sky-950  ">
            <div className="relative bg-n-8 rounded-[1rem]">
              <div className="h-[1.4rem] bg-sky-900 rounded-t-[0.9rem]" />

                <div className=' aspect-[200/110] rounded-b-[0.9rem] overflow-hidden'>
                  <img src={landingImage} 
                  className='w-full rounded-b-[0.9rem]
                  scale-[1.7] translate-x-[25%]'
                  alt="banner"/>
 <div className="absolute px-1 py-1  bg-slate-950/80 backdrop-blur border border-n-1/10 rounded-2xl left-4 right-4 bottom-5 md:left-1/2 md:right-auto
  md:bottom-8 md:w-[31rem] md:-translate-x-1/2
  flex flex-row items-center gap-5" >
    <img src= {tanoEdImage} className=' w-10 h-10' alt="TanoEdrobot" /> 
<p className=' font-sans'> Plan personnalisé par l'I.A. </p>
</div>

<ScrollParallax isAbsolutelyPositioned>
<ul className=" hidden
md:flex md:flex-col md:translate-x-8 
lg:flex-row absolute -left-[5.5rem] bottom-[7.5rem] px-1 py-1  bg-sky-800/40 backdrop-blur border border-n-1/10 rounded-2xl xl:flex">
{heroIcons.map((icon, index) => (
                      <li className="p-5" key={index}>
                        <img src={icon} width={34} height={25} alt={icon} />
                      </li>
                    ))}
                  </ul>
                </ScrollParallax>
                <ScrollParallax isAbsolutelyPositioned>
                  <div
                    className=" hidden md:flex  md:flex-col
                    absolute  bg-sky-800/40  backdrop-blur border border-n-1/10 rounded-2xl  -right-[17.5rem] bottom-[11rem] w-[30rem] xl:flex"
                    title="Code generation"
                  >
                <div className=' p-2 hidden lg:flex lg:flex-col xl:flex-row gap-5 w-full'>
                  <div className='  rounded-3xl'>
                  <img src={Chart}  width={100} height={100}  alt='dashboard'/>
                  </div>
                 <div className=' flex flex-col text-sm font-semibold '>
                    <table>
                      <tr>
                        <td className=' p-2'>
                          Nombres et calculs
                          </td>
                          <td>
                           <span className=' bg-green-200 text-green-800 px-2 rounded'>Acquis </span>
                            </td>
                            </tr>

                            <tr>
                        <td className=' p-2'>
                          Espace et géométrie
                          </td>
                          <td>
                           <span className=' bg-yellow-200 text-orange-800 px-2 rounded'>En cours d'acquisition</span>
                            </td>
                            </tr>

                            <tr>
                        <td className=' p-2'>
                          Grandeurs et mesures
                          </td>
                          <td>
                           <span className=' bg-red-200 text-red-800 px-2 rounded'>Non acquis </span>
                            </td>
                            </tr>
                    </table>
                  </div>
                </div>
                  </div>
                </ScrollParallax>
                </div>
      
          </div>
          <Gradient /> 
        </div>

{!isMd && <BackgroundCircles /> }
      </div>
      </div>
      
   
      </div>

  

  
    );
  }
  
  export default Banner;

  const CurveComponent = () => {
    return (
      <svg width="100%" height="100" viewBox="0 0 800 100" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="blueGreenGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: '#66ccff', stopOpacity: 1 }} /> {/* Bleu clair */}
            <stop offset="100%" style={{ stopColor: '#a0f080', stopOpacity: 1 }} /> {/* Vert clair */}
          </linearGradient>
        </defs>
        <path
          d="M 50 80 C 250 30, 550 30, 750 80"
          fill="transparent"
          stroke="url(#blueGreenGradient)"
          strokeWidth="10"
        />
      </svg>
    );
  };

  
 
  