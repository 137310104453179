// Dans Header.js
import React from "react";
import Projet from "../Projet";
import { product4 } from "../constants";
function ProgressionPlus({handleAccueilClick, handleProductsClick, handleWhyClick, handleProjetClick}) {


 
    return (
   <div >
   <Projet  roadmap={product4} recentproject={"Progression"}/>
  
    </div>
    );
  }
  
  export default ProgressionPlus;